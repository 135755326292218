@import "src/assets/scss/abstract";



















@import '../../../node_modules/bootstrap/scss/dropdown';

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}
