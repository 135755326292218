@import "src/assets/scss/abstract";






















































































































































































































































.backdrop,
aside {
  z-index: $zindex-fixed;
}

.backdrop {
  background: $modal-backdrop-bg;
  opacity: $modal-backdrop-opacity;
}

aside {
  width: rem(280);
  background: $color-darkfull;
  border-right: 2px solid $secondary;

  .is-collapsed & {
    width: rem(70) + $scrollbar-width;
  }
}

.toggle {
  font-size: rem(12);
  color: $gray-600;
  padding: rem(6);
}

.inner-item {
  padding: map-get($spacers, 4);

  @include media-breakpoint-up(xl) {
    padding: map-get($spacers, 5);
  }

  .is-collapsed & {
    padding-left: rem(15);
    padding-right: rem(15);
  }
}

.logo {
  color: $light;
  font-weight: 600;
  font-size: rem(16);

  img {
    height: rem(40) + $scrollbar-width;
  }

  .is-collapsed & {
    img {
      height: rem(64);
    }
  }
}

.menu-item {
  a {
    display: flex;
    color: $text-muted;
    padding: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 1);
    outline: none;

    &:hover,
    &:focus {
      color: $info;
    }
  }

  .router-link-active {
    background-color: $primary;

    &,
    &:hover,
    &:focus {
      color: $white;
    }
  }

  i {
    padding: rem(10);
  }

  .is-collapsed & {
    a {
      display: inline-flex;
      padding: 0;
      margin-bottom: map-get($spacers, 3);
    }
  }
}

.children {
  padding: map-get($spacers, 2) 0;

  .menu-item {
    a {
      padding: rem(10) rem(18) rem(10) rem(61);
    }

    .router-link-active {
      background-color: rgba($primary, 0.25);
      color: $color-light;
    }
  }

  .router-link-active + & {
    display: block;
  }

  &,
  .is-collapsed & {
    display: none;
  }
}

.menu-label,
.user-text {
  .is-collapsed & {
    display: none;
  }
}

.user-text {
  padding: 0 rem(20);
}
