@import "src/assets/scss/abstract";





























$primary: #1faedb;

#app {
  background: linear-gradient(180deg, $color-darker 0%, $primary 90%);
}
.bg-white {
  background-color: $light !important;
}
.inner {
  border: 1px solid $primary;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: rem(360);
  }
}
