@import "src/assets/scss/abstract";


























main {
  background-color: rgba($bg-primary, 0.7);
  margin-left: rem(70);

  @include media-breakpoint-up(xl) {
    margin-left: rem(280);
  }
}
