input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.fieldset {
  background: linear-gradient(
    160deg,
    $table-hover-bg 0%,
    darken($table-hover-bg, 2%) 70%,
    darken($table-hover-bg, 1%) 100%
  );
  padding: map-get($spacers, 3);
  border-radius: $border-radius-lg;
}
