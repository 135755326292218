@import "src/assets/scss/abstract";
















$modal-inner-padding: rem(32);

@import '../../../node_modules/bootstrap/scss/modal';
